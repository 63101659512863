import { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive'

import './Carousel.scss';

import rightArrowIcon from 'assets/svg/right-arrow-icon.svg';
import leftArrowIcon from 'assets/svg/left-arrow-icon.svg';
import { ceil } from "lodash";

export default function Carousel(props) {
    const [active, setActive] = useState(0);
    const [isFirstPartner, setIsFirstPartner] = useState(true);
    const [isLastPartner, setIsLastPartner] = useState(false);
    const [offset, setOffset] = useState(0);
    const ref = useRef();
    const length = useMediaQuery({ minWidth: 992 }) ? 4 : 2;

    useEffect(() => {
        if(props.items) {
          if(active === 0) {
            setIsFirstPartner(true);
          } else {
            setIsFirstPartner(false);
          }
    
          if(active === ceil((props.items.length/length)-1)) {
            setIsLastPartner(true);
          } else {
            setIsLastPartner(false);
          }
    
          setOffset( active * ( 
            ref.current.offsetWidth
            // + parseFloat(getComputedStyle(ref.current).getPropertyValue('margin-right'))
          ));
        }
    }, [active]);

    const goToPrev = () => {
        if(active > 0) setActive(prevActive => prevActive-1);
    }

    const goToNext = () => {
        if(props.items && active < (props.items.length/length)-1) setActive(prevActive =>  prevActive+1);
    }

    return (
        <div className="carousel">
            <button onClick={goToPrev} className="carousel__button v_prev" style={{ opacity: isFirstPartner ? '0.2' : '1' }}>
                <img src={leftArrowIcon} alt="Previous" width="25" />
            </button>
            <div className="carousel__container">
                <ul ref={ref} className="carousel__list" style={{ transform: `translateX(-${offset}px` }}>
                    {props.items.map((partner, index) => {
                        return (
                            <li className="carousel__list__item">
                                <img className="carousel__list__item__image" src={partner.sizes.medium} alt={partner.alt} />
                            </li>
                        )}
                    )}
                </ul>
            </div>
            <button onClick={goToNext} className="carousel__button v_next" style={{ opacity: isLastPartner ? '0.2' : '1' }}>
                <img src={rightArrowIcon} alt="Next" width="25" />
            </button>
        </div>
    )
}