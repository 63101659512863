import { useState } from 'react';

import AdoozyPowerFAQItem from './AdoozyPowerFAQItem';

import './AdoozyPowerFAQ.scss';

export default function AdoozyPowerFAQ(props) {
  const [activeItem, setActiveItem] = useState(0);

  const updateActiveItem = (index) => {
    setActiveItem(index);
  }

  return (
    <div className="adoozy_power_faqs">
      <h2 className="adoozy_power_faqs__title">FAQ's</h2>
      <ul className="adoozy_power_faqs__list">
        {props.items.map((item, index) => <AdoozyPowerFAQItem key={index} index={index} is_active={index === activeItem ? true : false} question={item.question} answer={item.answer} updateActiveItem={updateActiveItem} />)}
      </ul>
    </div>
  );
}
