import { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import * as THREE from 'three';
import { useFrame } from "@react-three/fiber";
import { motion } from "framer-motion";

import { fragmentShader } from "../../shaders/fragment.js";
import { vertexShader } from "../../shaders/vertex.js";

export default function DynamicGradient(props) {
  const ref = useRef();
  const [rotation, setRotation] = useState([0, 0, 0]);

  const data = useMemo(() => ({
    extensions: {
      derivatives: "#extension GL_OES_standard_derivatives : enable"
    },
    side: THREE.DoubleSide,
    uniforms: {
      time: { value: 0 },
      resolution: { value: new THREE.Vector4() },
    },
    fragmentShader,
    vertexShader
  }), []);

  const getMousePos = (e) => {
    return { x: e.clientX, y: e.clientY };
  };

  const onWindowMouseMoveEvent = useCallback(e => {
    const { x, y } = getMousePos(e);
    setRotation([x*0.0005, x*0.0005, y*0.0005]);
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", onWindowMouseMoveEvent);

    return () => {
      window.removeEventListener("mousemove", onWindowMouseMoveEvent);
    };
  }, [onWindowMouseMoveEvent]);

  useFrame((state) => {
    ref.current.material.uniforms.time.value = ref.current.material.uniforms.time.value + 0.005;
  });

  return (
    <mesh ref={ref} rotation={rotation}>
      <sphereBufferGeometry args={[2, 32, 32]} attach="geometry" />
      <shaderMaterial attach="material" {...data} />
    </mesh>
  );
}
