import { Canvas } from "@react-three/fiber";
import { Mobile, Tablet, Desktop } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';

import Mesh from './Mesh';

import portraitBackgroundImage from 'assets/img/portrait-gradient-background.jpg';
import landscapeBackgroundImage from  'assets/img/landscape-gradient-background.jpg';

export default function DynamicGradient(props) {

  return (
    <>
      <Desktop>
        <Canvas
          camera={{
            position: [1.5761772369684713, -0.22479083179279932, -0.46500087395578255],
            rotation: [2.541672533298699, 1.2921488641941372, -2.5598682893850517],
            fov: 75,
            aspect: window.innerWidth / window.innerHeight,
            near: 1,
            far: 1000
          }}
          dpr={Math.min(window.devicePixelRatio, 2)}
          onCreated={canvasCtx => {
            canvasCtx.gl.physicallyCorrectLights = true;
          }}
        >
          <Mesh />
        </Canvas>
      </Desktop>
      <Tablet>
        <img src={landscapeBackgroundImage} style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', display: 'block' }} alt="" width="" height="" />
      </Tablet>
      <Mobile>
        <img src={portraitBackgroundImage} style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', display: 'block' }} alt="" width="" height="" />
      </Mobile>
    </>
  );
}
