import { motion } from "framer-motion";

import btnArrowIcon from "../../assets/svg/scroll-btn-down-arrow-icon.svg";

const transition = {
  duration: 1,
  delay: 1,
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function PageHeroContent(props) {

  return (
    <h1 className="page_hero__title">
      <motion.em
        initial={{ y: '50%', opacity: 0, scale: 0.9 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: '-50%' }}
        transition={{ ...transition, type: 'spring' }}
        className="page_hero__title__main"
      >
        {props.first_line}
      </motion.em>
      <motion.span
        initial={{ y: '50%', opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: '-50%', opacity: 0 }}
        transition={{ ...transition, delay: 1.5, duration: 0.6 }}
        className="page_hero__title__sub"
      >
        {props.second_line}
      </motion.span>
      <motion.button
        initial={{ y: 0 }}
        animate={{ y: '5%' }}
        transition={{
          duration: 0.6,
          delay: 4.5,
          ease: [0.43, 0.13, 0.23, 0.96],
          repeat: 3,
          repeatType: 'mirror'
        }}
        className="page_hero__title__scroll_btn"
        onClick={(e) => props.scrollTo(e)}
      >
        <motion.span
          initial={{ scaleY: 0 }}
          animate={{ scaleY: 1 }}
          exit={{ scaleY: 0 }}
          transition={{ duration: 0.4, delay: 2 }}
          className="page_hero__title__scroll_btn__top_motif"
        >
          <span className="page_hero__title__scroll_btn__top_motif__line"></span>
        </motion.span>
        <motion.span
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 10, scale: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6, delay: 2.4, type: 'spring' }}
          className="page_hero__title__scroll_btn__text"
        >
          Scroll for More
        </motion.span>
        <span className="page_hero__title__scroll_btn__bottom_motif">
          <motion.span
            initial={{ y: '-50%', opacity: 0, scale: 0.9 }}
            animate={{ y: 0, opacity: 10, scale: 1 }}
            exit={{ y: '50%', opacity: 0 }}
            transition={{ duration: 0.4, delay: 3 }}
            className="page_hero__title__scroll_btn__bottom_motif__line"
          ></motion.span>
          <motion.img
            initial={{ y: '50%', opacity: 0 }}
            animate={{ y: '-8px', opacity: 1 }}
            exit={{ y: '-50%', opacity: 0 }}
            transition={{ duration: 0.4, delay: 3.4 }}
            className="page_hero__title__scroll_btn__bottom_motif__icon"
            src={btnArrowIcon}
            alt=""
          />
        </span>
      </motion.button>
    </h1>
  );
}
