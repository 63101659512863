// import { hero, how_it_works_items, prices, products, faqs, partners } from "data/Power.js";
import { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Mobile, Tablet, Desktop } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';

import './Power.scss';

import Page from 'components/PageComponent/Page';
import Header from 'components/HeaderComponent/Header';
import PageHero from 'components/PageHeroComponent/PageHero';
import PageHeroMobile from 'components/PageHeroComponent/PageHeroMobile';
import PageMenu from 'components/PageMenuComponent/PageMenu';
import AdoozyPowerHowItWorks from 'components/AdoozyPowerHowItWorksComponent/AdoozyPowerHowItWorks';
import AdoozyPowerPricing from 'components/AdoozyPowerPricingComponent/AdoozyPowerPricing';
import AdoozyPowerProducts from 'components/AdoozyPowerProductsComponent/AdoozyPowerProducts';
import AdoozyPowerFAQ from 'components/AdoozyPowerFAQComponent/AdoozyPowerFAQ';
import CTA from 'components/CTAComponent/CTA';
import Footer from 'components/FooterComponent/Footer';
import DynamicGradient from 'components/DynamicGradientComponent/DynamicGradient';
import Loading from 'components/LoadingComponent/Loading';

function AdoozyPowerView() {
  const slug = 'power';
  const [data, setData] = useState(null);
  const [products, setProducts] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(data => setData(data));

    fetch(`${process.env.REACT_APP_REST_API_URL}/post-type/products`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(data => setProducts(data));
  }, []);

  const howItWorksRef = useRef(), pricingRef = useRef(), productsRef = useRef(), faqsRef = useRef(), partnersRef = useRef();

  const sections = [
    {
      ref: howItWorksRef,
      name: "How it Works"
    },
    {
      ref: productsRef,
      name: "Products"
    },
    {
      ref: pricingRef,
      name: "Pricing"
    },
    {
      ref: faqsRef,
      name: "FAQ's"
    },
    {
      ref: partnersRef,
      name: "Partners"
    }
  ];

  if(!data || !products) return <Loading />

  return (
    <Page>
      <Header showHamburger={true}/>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.6, delay: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
        className="canvasContainer"
      >
        <DynamicGradient />
      </motion.div>
      <motion.div
        initial={{ y: '100%', opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96], stiffness: 100 }}
        style={{ backgroundColor: '#333333' }}
      >
        <Mobile>
          <PageHeroMobile
            first_line={data.metadata.first_line}
            second_line={data.metadata.second_line}
            image_large={data.metadata.image.url}
            image_medium={data.metadata.image.sizes['large']}
            image_small={data.metadata.image.sizes['medium_large']}
          />
        </Mobile>
        <Tablet>
          <PageHeroMobile
            first_line={data.metadata.first_line}
            second_line={data.metadata.second_line}
            image_large={data.metadata.image.url}
            image_medium={data.metadata.image.sizes['large']}
            image_small={data.metadata.image.sizes['medium_large']}
          />
        </Tablet>
        <Desktop>
          <PageHero
            first_line={data.metadata.first_line}
            second_line={data.metadata.second_line}
            image_large={data.metadata.image.url}
            image_medium={data.metadata.image.sizes['large']}
            image_small={data.metadata.image.sizes['medium_large']}
          />
        </Desktop>
      </motion.div>
      <PageMenu sections={sections} />
      <div ref={howItWorksRef}></div>
      <AdoozyPowerHowItWorks items={data.metadata.how_it_works}  />
      <div ref={pricingRef}></div>
      <AdoozyPowerPricing items={data.metadata.pricing} />
      <div ref={productsRef}></div>
      <AdoozyPowerProducts items={products} />
      <div ref={faqsRef}></div>
      <AdoozyPowerFAQ items={data.metadata.faqs} />
      <div ref={partnersRef}></div>
      <CTA title={data.metadata.title} description={data.metadata.description} link={data.metadata.link} link_text={data.metadata.link_text} image={data.metadata.banner_image.sizes['2048x2048']} background={data.metadata.background_image.sizes.large} />
      <Footer />
    </Page>
  );
}

export default AdoozyPowerView;
