import { motion } from 'framer-motion';

import { Link } from 'react-router-dom';

import './CTA.scss';

export default function CTA(props) {

  return (
    <div className="cta" style={{ backgroundImage: `url(${props.background})` }}>
      <div className="cta__content">
        <img className="cta__content__image" src={props.image} alt="" width="" height="" />
        <motion.h2
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.4, delay: 0.6 }}
          variants={{
            visible: { x: 0, opacity: 1 },
            hidden: { x: '-100%', opacity: 0 }
          }}
          className="cta__content__title"
        >{props.title}</motion.h2>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.4, delay: 1 }}
          variants={{
            visible: { y: 0, opacity: 1 },
            hidden: { y: '100%', opacity: 0 }
          }}
          className="cta__content__description"
          dangerouslySetInnerHTML={{ __html: props.description }}
        ></motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.4, delay: 1.4 }}
          variants={{
            visible: { y: 0, opacity: 1 },
            hidden: { y: '100%', opacity: 0 }
          }}
        >
          <Link to={props.link} className="cta__content__link btn">
            <span className="btn_text">{props.link_text}</span>
            <span className="btn_hover_state_background"></span>
            <span className="btn_hover_state_text">{props.link_text}</span>
          </Link>
        </motion.div>
      </div>
    </div>
  );
}
