import GalleriesItem from './GalleriesItem';

import './Galleries.scss';

const transition = {
  duration: 0.4,
  delay: 0.4,
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function Galleries(props) {

  // <div className="galleries">
  //   <ul className="galleries__list">
  //     {props.galleries.map((gallery, index) => <GalleriesItem key={index} gallery={gallery} />)}
  //   </ul>
  // </div>

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 0 84px' }}>
      <ul style={{ listStyleType: 'none', width: '78vw', margin: 0, padding: 0 }}>
        {props.galleries.map((gallery, index) => <GalleriesItem key={index} gallery={gallery} type={props.type} />)}
      </ul>
    </div>
  );
}
